<template>
  <div class="prose leading-9 p-5">
    <h2 id="privacy-policy">Privacy Policy</h2>
    <p><strong>Effective Date:</strong>  1st November 2024</p>
    <h3 id="1-introduction">1. Introduction</h3>
    <p>Triese Email Reader  (&quot;we,&quot; &quot;our,&quot; &quot;us&quot;) values your privacy. This Privacy Policy explains how we collect, use, and protect your information.</p>
    <h3 id="2-information-we-collect">2. Information We Collect</h3>
    <ul>
      <li><strong>Personal Information:</strong> Your name, email address, and Google account information obtained through Google Single Sign-On (SSO).</li>
      <li><strong>Usage Data:</strong> Data about your interaction with the App, including unsubscribing activity.</li>
    </ul>
    <h3 id="3-how-we-use-your-information">3. How We Use Your Information</h3>
    <ul>
      <li>To provide and improve the App’s functionality.</li>
      <li>To authenticate your identity through Google SSO.</li>
      <li>To communicate with you about updates, features, and support.</li>
    </ul>
    <h3 id="4-data-sharing">4. Data Sharing</h3>
    <p>We do not sell, trade, or rent your personal information. We may share information with:</p>
    <ul>
      <li><strong>Service Providers:</strong> Trusted third parties that help us operate the App.</li>
      <li><strong>Legal Authorities:</strong> If required to comply with legal obligations.</li>
    </ul>
    <h3 id="5-data-security">5. Data Security</h3>
    <p>We implement security measures to protect your information, including:</p>
    <ul>
      <li>Encryption during data transmission.</li>
      <li>Secure authentication using Google SSO.</li>
    </ul>
    <h3 id="6-data-retention">6. Data Retention</h3>
    <p>We retain your data only as long as necessary to provide the service or comply with legal obligations. You may request deletion of your data at any time by contacting [support email].</p>
    <h3 id="7-your-rights">7. Your Rights</h3>
    <ul>
      <li>Access, update, or delete your data by contacting us at  hello@triesie.io.</li>
      <li>Revoke access to your Gmail account at any time via your Google account settings.</li>
    </ul>
    <h3 id="8-third-party-services">8. Third-Party Services</h3>
    <p>The App may include links to third-party services. We are not responsible for their privacy practices.</p>
    <h3 id="9-changes-to-privacy-policy">9. Changes to Privacy Policy</h3>
    <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we will notify you of significant updates.</p>
    <h3 id="10-contact-us">10. Contact Us</h3>
    <p>For any questions or concerns about this Privacy Policy, contact us at hello@triesie.io.</p>
  </div>
</template>

<script>



export default {
  name: 'PrivacyPolicy'
}
</script>

<style scoped>
</style>
